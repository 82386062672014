




























































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import KeyElementsEdit from '@bertazzoni/back/components/KeyElementsEdit.vue'
import ContactEdit from '@bertazzoni/back/components/ContactEdit.vue'
import UploadFile from '@bertazzoni/back/components/UploadFile.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import QuizEdit from '@bertazzoni/back/components/QuizEdit.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import ConfirmationModal from '@bertazzoni/back/components/ConfirmationModal.vue'
import AssociateContent from '@bertazzoni/back/components/AssociateContent.vue'
import TagList from '@bertazzoni/back/components/TagList.vue'
import AccessFilter from '@bertazzoni/back/components/AccessFilter.vue'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import ModulesService from '@bertazzoni/common/src/services/ModulesService'
import TagsService from '@bertazzoni/common/src/services/TagsService'
import { getLanguageList } from '@bertazzoni/common/src/helpers/utils/utils'
import { FileTypeFormat } from '@bertazzoni/common/src/helpers/utils/fileManager'
import { UpdateModuleDto, Mod, Contact } from '@bertazzoni/common/src/models/module.model'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { Tag } from '@bertazzoni/common/src/models/tag.model'
import { Content } from '@bertazzoni/common/src/models/content.model'
import { Question } from '@bertazzoni/common/src/models/Question'
@Component({
  components: {
    Loader,
    FormError,
    Icons,
    UploadFile,
    DatePicker,
    KeyElementsEdit,
    ContactEdit,
    PaginatedTable,
    ConfirmationModal,
    AssociateContent,
    QuizEdit,
    TagList,
    AccessFilter
  }
})
export default class UpdateModule extends Vue {
  private currentModule: UpdateModuleDto = new UpdateModuleDto()
  private fullModule: Mod = new Mod()
  private resetUploaders = false
  private pageLoaded = false
  private submitLoading = false
  private discardLoading = false
  private formatFile = FileTypeFormat
  private imageLoad = false
  private selectedDelete: { id: string; name: string; type: string }
  private showDeleteModal = false
  private contentList: Content[] = []
  private languageList: string[] = []
  //private contactList: Contact[] = []
  private submitError = {
    date: false,
    quiz: false,
    tag: false,
    image: false,
    content: false,
    module: false,
    keyElement: false,
    contact: false
  }
  private tableInfoContent: TableInfo = new TableInfo()
  private tableInfoQuiz: TableInfo = new TableInfo()
  private questionToEdit: Question = null
  private searchQuizInput = ''
  private showNewQuestion = false
  private showContentModal = false
  private keyDate = 0
  private keyAccess = 0
  private keyTable = 0
  private tagToSelectList: string[] = []

  async created(): Promise<void> {
    this.setTableInfo()
    this.fullModule = await ModulesService.findOne(this.$route.params.id)
    this.languageList = await getLanguageList(true)
    this.resetModule()
    this.fullModule = JSON.parse(JSON.stringify(this.currentModule))
    await this.getTagList()
    await this.getContentList()
    //this.contactList = this.currentModule.contacts ? this.currentModule.contacts : []
    this.pageLoaded = true
  }
  async getTagList(): Promise<void> {
    this.tagToSelectList = []
    const tagList = await TagsService.findAll()
    tagList.map((tag: Tag) =>
      tag.tags.map((tagName: string) => this.tagToSelectList.push(`${tag.tagCategory}_${tagName}`))
    )
  }
  async resetModule() {
    this.discardLoading = true
    for (const key in this.currentModule) {
      this.currentModule[key] = this.fullModule[key]
        ? JSON.parse(JSON.stringify(this.fullModule[key]))
        : null
    }
    this.resetUploaders = !this.resetUploaders
    await this.getContentList()
    this.keyAccess++
    this.keyDate++
    this.discardLoading = false
  }
  async setTableInfo(): Promise<void> {
    this.tableInfoContent.getCustomTable().isPaginated = false
    this.tableInfoQuiz.getCustomTable().isPaginated = false
    this.tableInfoQuiz.getCustomTable().displayButton = false
  }
  async getContentList(): Promise<void> {
    if (this.currentModule.contentIDs && this.currentModule.contentIDs.length > 0) {
      this.contentList = await ContentService.findByIds(this.currentModule.contentIDs)
    } else {
      this.contentList = []
    }
  }
  deleteObject(): void {
    if (this.selectedDelete.type === 'content') {
      this.currentModule.contentIDs.splice(
        this.currentModule.contentIDs.indexOf(this.selectedDelete.id),
        1
      )
      this.getContentList()
    } else {
      this.currentModule.quiz.splice(parseInt(this.selectedDelete.id) - 1, 1)
      this.actualiseQuestionId()
    }
    this.showDeleteModal = false
  }
  removeContent(id: string, name: string): void {
    this.selectedDelete = { id: id, name: name, type: 'content' }
    this.showDeleteModal = true
    this.checkErrorQuiz()
  }
  openModalContent(): void {
    if (this.isLanguageFill) this.showContentModal = true
  }
  get isLanguageFill() {
    return this.currentModule.language !== ''
  }
  associateContents(contentList: string[]): void {
    this.currentModule.contentIDs = contentList
    this.getContentList()
    this.showContentModal = false
  }
  editQuestion(object: Question): void {
    this.questionToEdit = object
    this.showNewQuestion = true
  }
  selectTag(tag: string): void {
    this.currentModule.tags.push(tag)
  }
  removeTag(tag: string): void {
    this.currentModule.tags.splice(this.currentModule.tags.indexOf(tag), 1)
  }
  removeQuestion(id: string, name: string): void {
    this.selectedDelete = { id: id, name: name, type: 'question' }
    this.showDeleteModal = true
    this.checkErrorQuiz()
  }
  openNewQuestion(): void {
    this.questionToEdit = null
    this.showNewQuestion = true
  }
  addNewQuestion(newQuestion: Question): void {
    newQuestion.id = this.currentModule.quiz ? this.currentModule.quiz.length + 1 : 1
    this.currentModule.quiz
      ? this.currentModule.quiz.push(newQuestion)
      : (this.currentModule.quiz = [newQuestion])
    this.showNewQuestion = false
    this.questionToEdit = null
    this.checkErrorQuiz()
  }
  editNewQuestion(editQestion: Question): void {
    const i = this.currentModule.quiz.findIndex((quest: Question) => quest.id === editQestion.id)
    this.currentModule.quiz.splice(i, 1, editQestion)
    this.showNewQuestion = false
    this.questionToEdit = null
    this.keyTable++
    this.checkErrorQuiz()
  }
  actualiseQuestionId(): void {
    for (let index = 0; index < this.currentModule.quiz.length; index++) {
      this.currentModule.quiz[index].id = index + 1
    }
  }
  getStartingDate(newDate: Date): void {
    this.currentModule.startingDate = newDate ? new Date(newDate) : null
    this.keyDate++
  }
  getExpirationDate(newDate: Date): void {
    this.currentModule.expirationDate = newDate ? new Date(newDate) : null
    this.keyDate++
  }
  imageUpload(loading: boolean): void {
    this.imageLoad = loading
  }
  getImage(newImage: string): void {
    this.currentModule.imageUrl = newImage !== '' ? newImage : this.fullModule.imageUrl
  }
  setKeyElement(newKeyElement: string[]): void {
    this.currentModule.keyBenefits = newKeyElement
  }
  setContactList(newContactList: Contact[]): void {
    this.currentModule.contacts = newContactList
  }
  editAccessList(newAccessList: string[]): void {
    this.currentModule.accessEntityIds = newAccessList
  }
  reorderContents(): void {
    const contentsId = this.contentList.map((content) => content._id)
    this.currentModule.contentIDs.sort(function(a, b) {
      if (contentsId.indexOf(a) > contentsId.indexOf(b)) {
        return 1
      } else {
        return -1
      }
    })
  }

  async checkErrors(): Promise<void> {
    const isValid = await this.$validator.validateAll()
    this.checkErrorDate()
    this.checkErrorImg()
    this.checkErrorContent()
    this.checkErrorQuiz()
    this.checkErrorKeyElements()
    this.checkErrorContact()
    this.checkErrorTag()
    if (
      isValid &&
      !this.submitError.quiz &&
      !this.submitError.image &&
      !this.submitError.content &&
      !this.submitError.module &&
      !this.submitError.contact &&
      !this.submitError.keyElement &&
      !this.submitError.tag &&
      !this.submitError.date
    ) {
      if (this.currentModule != this.fullModule) {
        for (const key in this.currentModule) {
          if (JSON.stringify(this.currentModule[key]) !== JSON.stringify(this.fullModule[key])) {
            this.submitUpdateModule()
            return
          }
        }
      }
    }
  }
  checkErrorDate(): void {
    if (!this.currentModule.startingDate || this.currentModule.startingDate.toString() === '') {
      this.submitError.date = true
    } else {
      this.submitError.date = false
    }
  }
  checkErrorTag(): void {
    if (!this.currentModule.tags || this.currentModule.tags.length === 0) {
      this.submitError.tag = true
    } else {
      this.submitError.tag = false
    }
  }
  checkErrorKeyElements(): void {
    if (this.currentModule.keyBenefits && this.currentModule.keyBenefits.length > 0) {
      this.submitError.keyElement = false
    } else {
      this.submitError.keyElement = true
    }
  }
  checkErrorImg(): void {
    if (!this.currentModule.imageUrl || this.currentModule.imageUrl === '') {
      this.submitError.image = true
    } else {
      this.submitError.image = false
    }
  }
  async checkErrorContact(): Promise<void> {
    if (this.currentModule.contacts && this.currentModule.contacts.length > 0) {
      const validList = []
      for (let index = 0; index < this.currentModule.contacts.length; index++) {
        validList.push(this.$validator.validate(`contactName${index}`))
        validList.push(this.$validator.validate(`email${index}`))
      }
      const results = Promise.all(validList)
      this.submitError.contact = !(await results).every((isValid) => isValid)
    } else {
      this.submitError.contact = false
    }
  }
  checkErrorQuiz(): void {
    if (
      !this.currentModule.quiz ||
      this.currentModule.quiz.length < 5 ||
      this.currentModule.quiz.length > 5
    ) {
      this.submitError.quiz = true
    } else {
      this.submitError.quiz = false
    }
  }
  checkErrorContent(): void {
    if (
      !this.currentModule.contentIDs ||
      this.currentModule.contentIDs.length < 1 ||
      this.currentModule.contentIDs.length > 5
    ) {
      this.submitError.content = true
    } else {
      this.submitError.content = false
    }
  }
  getSendedModule(): UpdateModuleDto {
    const sendedContent: UpdateModuleDto = new UpdateModuleDto()
    for (const key in this.currentModule) {
      if (JSON.stringify(this.currentModule[key]) !== JSON.stringify(this.fullModule[key])) {
        sendedContent[key] = this.currentModule[key]
      }
    }
    if (this.currentModule.language !== this.fullModule.language) {
      sendedContent.language = this.currentModule.language
    }
    if (sendedContent.expirationDate) {
      sendedContent.expirationDate.toString()
    }
    return sendedContent
  }
  async submitUpdateModule(): Promise<void> {
    this.changeLoading()
    const sendedModule = this.getSendedModule()
    try {
      await ModulesService.updateOne(sendedModule, this.$route.params.id)
      this.changeLoading()
      this.$router.push('/module-list')
    } catch {
      this.changeLoading()
    }
  }
  changeLoading(): void {
    this.submitLoading = !this.submitLoading
  }
}
